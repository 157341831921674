console.log('Ready javascript');

// Mobile > all touchstar DOM perfect Iphone
document.addEventListener("touchstart", function(){}, true);

(function($) {
  "use strict";


  //OPEN sidebar content ( navigation 2 )
  $('.nicdark_open_navigation_2_sidebar_content,.nicdark_open_navigation_3_sidebar_content,.nicdark_open_navigation_4_sidebar_content,.nicdark_open_navigation_5_sidebar_content').on("click",function(event){
    $('.nicdark_navigation_2_sidebar_content,.nicdark_navigation_3_sidebar_content,.nicdark_navigation_4_sidebar_content,.nicdark_navigation_5_sidebar_content').css({
      'right': '0px',
      'box-shadow' : 'box-shadow:-4px 0px 20px #111'
    });
  });
  //CLOSE sidebar content ( navigation 2 )
  $('.nicdark_close_navigation_2_sidebar_content,.nicdark_close_navigation_3_sidebar_content,.nicdark_close_navigation_4_sidebar_content,.nicdark_close_navigation_5_sidebar_content').on("click",function(event){
    $('.nicdark_navigation_2_sidebar_content,.nicdark_navigation_3_sidebar_content,.nicdark_navigation_4_sidebar_content,.nicdark_navigation_5_sidebar_content').css({
      'right': '-300px',
      'box-shadow' : 'box-shadow:-4px 0px 20px #111'
    });
  });
  ///////////


  //OPEN search 
  $('.nicdark_navigation_2_open_search_content,.nicdark_navigation_3_open_search_content,.nicdark_navigation_4_open_search_content,.nicdark_navigation_5_open_search_content').on("click",function(event){
    $('.nicdark_navigation_2_search_content,.nicdark_navigation_3_search_content,.nicdark_navigation_4_search_content,.nicdark_navigation_5_search_content').css({
      'z-index': '9999',
      'opacity': '1',
      'display' : 'block',
    });
  });
  $('.nicdark_navigation_2_close_search_content,.nicdark_navigation_3_close_search_content,.nicdark_navigation_4_close_search_content,.nicdark_navigation_5_close_search_content').on("click",function(event){
    $('.nicdark_navigation_2_search_content,.nicdark_navigation_3_search_content,.nicdark_navigation_4_search_content,.nicdark_navigation_5_search_content').css({
      'z-index': '-1',
      'opacity': '0',
    });
  });
  ///////////


  //Masonry
  var $nicdark_masonry_content = $('.nicdark_masonry_content').imagesLoaded( function() {
    // init Masonry after all images have loaded
    $nicdark_masonry_content.masonry({
      itemSelector: '.nicdark_masonry_item'
    });
  });

  //tooltip
    $( '.nicdark_tooltip_jquery' ).tooltip({ 
      position: {
        my: "center top",
        at: "center+0 top-45",
      }
    });

    //tabs
    $( ".nicdark_tabs" ).tabs();
    
    //typed
  $(".nicdark_typed").typed({
      stringsElement: $('.nicdark_typed_strings'),
      typeSpeed: 100,
      backDelay: 500,
      loop: true,
      contentType: 'html',
      loopCount: false
  });
  
})(jQuery);




